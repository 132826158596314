import { Atom } from ":mods";
import "../../css/schedule.css";
import { Show } from "solid-js";
import { For } from "solid-js";

export function ListSchedule(props: { schedulesList: any }) {
  return (
    <div class="flex flex-col gap-40px pt-5">
      <p class="main-description">
        {" "}
        {props.schedulesList?.description ??
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia magni laboriosam dolorem, eveniet ipsa voluptas ipsam, cupiditate, eum recusandae non sunt repellendus iste consequatur qui deserunt error id dolorum perspiciatis?"}
      </p>
      <Show
        when={props.schedulesList?.schedules && props.schedulesList?.schedules.length > 0}
        fallback={"There's no events planned!"}
      >
        <For each={props.schedulesList?.schedules}>
          {(item, index) => {
            item["event_details"] = {
              description: item.description,
              date: item.date,
              time: item.date_time,
              type: item.event_type,
              action: "Join live stream ",
            };
            return (
              <Atom.Card.Event
                disable_edit
                disable_delete
                event_id={item.id}
                title={item.name}
                event_details={item.event_details}
                onOpen={() => {
                  window.open(item.link, "_blank");
                }}
              />
            );
          }}
        </For>
      </Show>
    </div>
  );
}
